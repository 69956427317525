.form-avatar {
  float: left;
  height: 120px;
  width: 120px;
  margin-right: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: #fff;
  overflow: hidden;

  img {
    height: 120px;
    display: block;
    margin: 0 auto;
  }
}

.caption {
  margin-bottom: 1px;
}
.examplethree {
  background-color: #ff80AB;
  color: #fff;
  text-align: center;
  font-weight: bold;
}

.vihodnoi {
  background-color: #F2F2F2;
  color: #adadad;
  text-align: center;
}

.nav { 
  float: left;
  width: 180px;

}   .string { 
  text-align: center; 
  }

  .tables {
    display: flex;
  }
  
  .column:first-child {
    width: 50%;
    padding-right: 15px;
  }
  
  .column:last-child {
    width: 50%;
    padding-left: 15px;
  }

  .block {
    position: sticky;
    top: 0px;
    z-index: 10;
    background-color: #FFC7CE		;
  }

  .right-side {
    position: relative;
    float: right;
    top:250px;
    right:90px;
  }
  .linetime {
    position: absolute;
    top:500px;
    right:50px;
  }
  .title-table{
    background-color:#0F6CBD;
    color:#FFF;
    padding: 5px 5px;
    text-align: center;
  }
  .dx-datagrid .dx-row > td {
    padding: 5px 5px;
    font-size: 14px;
    line-height: 20px;
}
.dx-icon-closes{
  position: absolute;
  top:14px;
  right:15px;
  font-size: 25px;
}
.dx-data-row.dx-state-hover:not(.dx-selection):not(.dx-row-inserted):not(.dx-row-removed):not(.dx-edit-row):not(.dx-row-focused) > td:not(.dx-focused) {
  cursor: pointer;
}
abbr {
  border-bottom: none;
  cursor: help;
  text-decoration: none;
 }

 .zayavka {
  position: relative;
  
  top:5px;
   margin: 10px;

}
.info {
  background-color: #8EA9DB;
  text-align: center;
}
.pointlink {
  cursor: pointer;
  color:#0F6CBD;
}

.disable-date {
  height: 100%;
  width: 100%;
  background-image:
          repeating-linear-gradient(
                          135deg,
                          rgba(244, 67, 54, 0.1),
                          rgba(244, 67, 54, 0.1) 4px,
                          transparent 4px,
                          transparent 9px
          );
  color: #9b6467;
}
.diveditlink{
  margin-bottom: 25px;
}
.soobsh{
  background-color: #B4C6E7;
  text-align: center;
  font-size: 1.1rem;
}